import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingInterface } from '@state/settings/settings.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(private httpClient: HttpClient) {}

  getSettings(): Observable<SettingInterface[]> {
    return this.httpClient.get<SettingInterface[]>('{api}/settings');
  }

  getSetting(key: string): Observable<string> {
    return this.httpClient.get<string>(`{api}/settings/${key}`);
  }

  updateSetting(key: string, value: string | number): Observable<string> {
    return this.httpClient.post<string>(`{api}/settings/${key}`, { value });
  }
}
